/* jshint esversion: 6 */

import React from 'react';
import axios from 'axios';
import * as Constants from './Constants';
import * as QueryString from 'query-string';

const Home = (props) => {
    'use strict';
    const [workplaces, setWorkplaces] = React.useState([]);
    const params = QueryString.parse(props.location.search);
    const planplus = axios.create({
        baseURL: Constants.BASE_URL,
        auth: {
            username: params.user,
            password: params.pass
        }
    });

    React.useEffect(() => {
        const getWorkplaces = async () => {
            const result = await planplus.get('/hr/work-orders/work-places/');
            setWorkplaces(result.data);
        };
        getWorkplaces();
    }, []);

    const handleOnClick = (id, label) => {
        props.history.push(`/workplace?id=${id}&label=${label}&user=${params.user}&pass=${params.pass}`);
    };

    return (
         /* jshint ignore:start */
        <div>
            <h3 className="title margin-bottom">Odaberite radno mjesto</h3>
            <button className="btn-odustani margin-bottom" onClick={() => {props.history.goBack()}}>Logout</button>
            {console.log(typeof workplaces)}
            {workplaces.map((workplace => {
                return(
                    <div className="workplace" onClick={() => { handleOnClick(workplace.id, workplace.name) }}>
                        <h3>{workplace.name}</h3>
                    </div>
                )
            }))}
        </div>
         /* jshint ignore:end */
    );
};

export default Home;