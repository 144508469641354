/* jshint esversion: 6 */

import React from 'react';
import axios from 'axios';
import * as Constants from './Constants';
import Moment from 'react-moment';
import 'moment-timezone';

const Inprocess = (props) => {
    'use strict';
    const planplus = axios.create({
        baseURL: Constants.BASE_URL,
        auth: {
            username: props.user,
            password: props.pass
        }
    });

    const [orders, setOrders] = React.useState([]);

    const getOrders = async () => {
        const { data } = await planplus.get(`/hr/work-orders/in-process/?work_place=${props.id}`);
        setOrders(data.results);
    };

    React.useEffect(() => {
        getOrders();
    }, []);

    const handleOnClick = async (id) => {
        const response = await planplus.put(`/hr/work-orders/set-finished/${id}/`);
        props.handleRefresh();
    };

    const renderOrders = () => {
        return (
            /* jshint ignore:start */
            <div className="order-wrap">
                {orders.map((order) => {
                    return (
                        <div key={order.id} className="order">
                            <div className="col1">
                                <p className="col-primary">{order.label} | <Moment date={order.in_process_date} format="DD.MM.YYYY HH:mm:ss" /></p>
                                <p><span className="bold">Stol:</span> {order.table}</p>
                                <p><span className="bold">Tip:</span> {order.course_name}</p>
                                {order.workorderitem_set.map((item) => {
                                    return (
                                        <p>
                                            {item.item_name} x {Math.trunc(item.quantity)}
                                            {item.extras_set.map((extras) => {
                                                return (
                                                    <p>
                                                        - {extras.name}
                                                    </p>
                                                )
                                            })}
                                        </p>
                                    )
                                })}
                            </div>
                            <div onClick={() => { handleOnClick(order.id) }} className="btn">
                                <p className="arr-ico">&rsaquo;</p>
                            </div>
                        </div>
                    )
                })}

            </div>
            /* jshint ignore:end */
        );
    };

    return (
        /* jshint ignore:start */
        <div className="w30perc">
            <h4 className="subtitle margin-bottom">In process</h4>
            <div className="d2">
            <div className="d3">
                <div className="d4">
                    <div className="d5">
                        {renderOrders()}
                    </div>
                </div>
            </div>
        </div>
        </div>
        /* jshint ignore:end */
    );
};

export default Inprocess;