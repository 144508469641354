/* jshint esversion: 6 */

import React from 'react';
import axios from 'axios';
import * as Constants from './Constants';
import Moment from 'react-moment';
import 'moment-timezone';

const Finished = (props) => {
    'use strict';
    const [orders, setOrders] = React.useState([]);

    React.useEffect(() => {
        const planplus = axios.create({
            baseURL: Constants.BASE_URL,
            auth: {
                username: props.user,
                password: props.pass
            }
        });
        const getOrders = async () => {
            const { data } = await planplus.get(`/hr/work-orders/finished/?work_place=${props.id}`);
            setOrders(data.results);
        };
        getOrders();
    }, []);

    const renderOrders = () => {
        return (
            /* jshint ignore:start */
            <div className="order-wrap">
                {orders.map((order) => {
                    return (
                        <div key={order.id} className="order">
                            <div className="col1">
                                <p className="col-primary">{order.label} | <Moment date={order.finished_date} format="DD.MM.YYYY HH:mm:ss" /></p>
                                <p><span className="bold">Stol:</span> {order.table}</p>
                                <p><span className="bold">Tip:</span> {order.course_name}</p>
                                {order.workorderitem_set.map((item) => {
                                    return (
                                        <p>
                                            {item.item_name} x {Math.trunc(item.quantity)}
                                            {item.extras_set.map((extras) => {
                                                return (
                                                    <p>
                                                        - {extras.name}
                                                    </p>
                                                )
                                            })}
                                        </p>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            /* jshint ignore:end */
        );
    };

    return (
        /* jshint ignore:start */
        <div className="w30perc">
            <h4 className="subtitle margin-bottom">Finished</h4>
            <div className="d2">
                <div className="d3">
                    <div className="d4">
                        <div className="d5">
                            {renderOrders()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        /* jshint ignore:end */
    );
};

export default Finished;