/* jshint esversion: 6 */

import ReactDOM from 'react-dom';
import App from './components/App';
import './css/style.css';

ReactDOM.render(
    /* jshint ignore:start */
    <App />, document.querySelector('#root')
    /* jshint ignore:end */
);